import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import { ProductType } from '@pig-common/types/Product.type'
import {
  CampaignType,
  ICampaignsData,
  ICampaignsResultData,
  IDoubleDigitData,
} from '@pig-casino/types/Campaign.type'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { AxiosRequestConfig } from 'axios'

export interface IParamsProps {
  types?: CampaignType
  productType?: ProductType
}

async function list(
  params: IParamsProps,
): Promise<CommonResponse<ICampaignsData>> {
  return await api.get('/v1/campaigns/', {
    params: {
      ...params,
      product_type: params?.productType,
    },
  })
}

async function getByCode(
  campaignCode: string | string[],
): Promise<CommonResponse<ICampaignsResultData>> {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/campaigns/code/${campaignCode}`,
    method: 'GET',
  }
  const result = await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
  return result
}

async function getDoubleDigit(): Promise<CommonResponse<IDoubleDigitData>> {
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/loyalty/events/double_digit',
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

export const key = {
  list: 'LIST_CAMPAIGN',
  getByCode: 'GET_CAMPAIGN_BY_CODE',
  getDoubleDigit: 'GET_DOUBLE_DIGIT',
}

export { list, getByCode, getDoubleDigit }
