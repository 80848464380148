import { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import parse from 'html-react-parser'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import {
  authStateCommon,
  bigWinStateCommon,
  inboxStateCommon,
} from '@pig-common/recoils'
import {
  InboxMessage,
  mapToInboxItem,
  mapToInboxMessage,
} from '@pig-common/mappers/mapToInboxItem'
import { useAxios } from '@pig-common/hooks/useAxios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { notification } from '@pig-frontend/uikit'
import { camelizeKeys } from '@pig-frontend/utils'
import { ICoinWalletResult } from '@pig-common/types/Bonus.type'
import { InboxItem } from '@pig-common/recoils/modules/inbox'
import { useSocket } from '@pig-common/context/Provider/Socket'
import {
  BigWinChampionDetail,
  BigWinState,
} from '@pig-common/recoils/modules/bigwin-list'
import useListChampion from '@pig-casino/hooks/useListChampion'
import useLegacySystemStatus from '@pig-common/hooks/useLegacySystemStatus'

export type BonusEvent = {
  activeBonus: ICoinWalletResult[]
  completedBonus: ICoinWalletResult[]
}

export type CategorisedInbox = {
  system: inboxStateCommon.InboxItem[]
  affiliate: inboxStateCommon.InboxItem[]
  transaction: inboxStateCommon.InboxItem[]
}

export const inboxSelector = (
  inboxList: inboxStateCommon.InboxItem[],
): CategorisedInbox => {
  return {
    system: inboxList.filter((i) => i.type === 'system'),
    affiliate: inboxList.filter((i) => i.type === 'affiliate'),
    transaction: inboxList.filter((i) => i.type === 'transaction'),
  }
}

interface SystemStatusInbox {
  date_time: Date
  icon: string
  inbox_type: 'transaction' | 'affiliate' | 'system'
  is_read: boolean
  message: string
  subject: string
  subtitle: string
  uid: string
  user_uid: string
}

interface SystemStatusData {
  Inbox: SystemStatusInbox[]
}

interface SystemStatusResponse {
  data: SystemStatusData
}

const updateInboxs = (
  currentInboxList: inboxStateCommon.InboxItem[],
  updateInboxList: inboxStateCommon.InboxItem[],
): inboxStateCommon.InboxItem[] => {
  return currentInboxList.map((currentInbox) => {
    const updatingInbox = updateInboxList.find(
      (inbox) => inbox.uid === currentInbox.uid,
    )
    if (updatingInbox) return updatingInbox
    return currentInbox
  })
}

const insertInboxs = (
  currentInboxList: inboxStateCommon.InboxItem[],
  insertInboxList: inboxStateCommon.InboxItem[],
): inboxStateCommon.InboxItem[] => {
  let result = currentInboxList
  insertInboxList.forEach((insertingInbox) => {
    const existingInsertInbox = result.find(
      (inbox) => inbox.uid === insertingInbox.uid,
    )
    if (!existingInsertInbox) {
      result = [insertingInbox, ...result]
    }
  })
  return result
}
const deleteInboxs = (
  currentInboxList: inboxStateCommon.InboxItem[],
  insertInboxList: inboxStateCommon.InboxItem[],
): inboxStateCommon.InboxItem[] => {
  const existingInsertInbox: inboxStateCommon.InboxItem | undefined =
    insertInboxList[0]
  return currentInboxList.filter(
    (inbox) => existingInsertInbox && inbox.uid !== existingInsertInbox.uid,
  )
}

export const insertAndUpdateInboxs = (
  currentInboxList: inboxStateCommon.InboxItem[],
  incomingInboxList: inboxStateCommon.InboxItem[],
): inboxStateCommon.InboxItem[] => {
  const insertedInboxs = insertInboxs(currentInboxList, incomingInboxList)
  const updatedInboxs = updateInboxs(insertedInboxs, incomingInboxList)
  return updatedInboxs
}

export const useInbox = () => {
  const [auth] = useRecoilState(authStateCommon.authState)
  const { pigspinApiInstance } = useAxios()
  const [, setBigWinState] = useRecoilState(bigWinStateCommon.bigWinState)
  const [, setInboxTransaction] = useRecoilState(
    inboxStateCommon.inboxTransactionState,
  )
  const [, setInboxAffiliate] = useRecoilState(
    inboxStateCommon.inboxAffiliateState,
  )
  const [, setInboxSystemStatus] = useRecoilState(
    inboxStateCommon.inboxSystemStatusState,
  )
  const [, setBonusState] = useRecoilState(inboxStateCommon.inboxBonusState)
  const { socket, connectSocket, disconnectSocket } = useSocket()
  const { collectSystemStatus } = useLegacySystemStatus()
  const { refetch: refetchChampion } = useListChampion({
    params: { page: 1, limit: 10 },
  })

  const updateSystemInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return
    setInboxSystemStatus((current) => {
      return {
        ...current,
        inboxs: insertAndUpdateInboxs(current.inboxs, inboxs),
      }
    })
  }

  const updateTransactionInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return
    setInboxTransaction((current) => {
      return {
        ...current,
        inboxs: insertAndUpdateInboxs(current.inboxs, inboxs),
      }
    })
  }
  const deleteTransactionInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return
    setInboxTransaction((current) => {
      return {
        ...current,
        inboxs: deleteInboxs(current.inboxs, inboxs),
      }
    })
  }
  const updateAffiliateInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return
    setInboxAffiliate((current) => {
      return {
        ...current,
        inboxs: insertAndUpdateInboxs(current.inboxs, inboxs),
      }
    })
  }
  const deleteAffiliateInbox = (inboxs: InboxItem[]) => {
    if (inboxs.length < 1) return
    setInboxAffiliate((current) => {
      return {
        ...current,
        inboxs: deleteInboxs(current.inboxs, inboxs),
      }
    })
  }

  const updateAllMessage = (allMessage: InboxMessage[]) => {
    const allInbox = allMessage.map(mapToInboxItem)
    const { transaction, affiliate, system } = inboxSelector(allInbox)
    updateTransactionInbox(transaction)
    updateAffiliateInbox(affiliate)
    const systemInboxs = system.filter(
      (item) =>
        item?.productType === undefined || item?.productType === 'PIGBET',
    )
    updateSystemInbox(systemInboxs)
  }
  const deleteAllMessage = (allMessage: InboxMessage[]) => {
    const allInbox = allMessage.map(mapToInboxItem)
    const { transaction, affiliate } = inboxSelector(allInbox)
    deleteTransactionInbox(transaction)
    deleteAffiliateInbox(affiliate)
  }

  const {
    isFetching: isFetchingTransaction,
    isFetchingNextPage: isFetchingTransactionNextPage,
    fetchNextPage: fetchNextTransactionPage,
    hasNextPage: hasNextTransactiontPage = false,
  } = useInfiniteQuery(
    ['transactions', auth.userUID],
    async ({ pageParam = 1 }) => {
      const page = pageParam
      if (!auth.userUID) return Promise.resolve([])
      const transactionsPerPage = 15
      const response = await fetchWithJSON<InboxMessage[]>(pigspinApiInstance, {
        url: `v1/inbox/messages/pagination/${
          auth.userUID
        }?page=${page}&length=${transactionsPerPage}&inbox_type=${'transaction'}`,
        method: 'GET',
      })
      return Promise.resolve(response)
    },
    {
      enabled: true,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length > 0) return (pages?.length || 1) + 1
        return null
      },
      onSuccess: ({ pages }) => {
        const mappedMessages = pages.flatMap((obj) => obj)
        const reduceDupMessages = Object.values(
          mappedMessages.reduce((obj, item) => {
            obj[item.uid] = item
            return obj
          }, {}),
        )
        updateAllMessage(reduceDupMessages)
      },
    },
  )

  const {
    isFetching: isFetchingAffiliate,
    isFetchingNextPage: isFetchingNextAffiliatePage,
    fetchNextPage: fetchNextAffiliatePage,
    hasNextPage: hasNextAffiliatePage = false,
  } = useInfiniteQuery(
    ['transactions-affiliate', auth.userUID],
    async ({ pageParam = 1 }) => {
      const page = pageParam
      if (!auth.userUID) return Promise.resolve([])
      const transactionsPerPage = 15
      const response = await fetchWithJSON<InboxMessage[]>(pigspinApiInstance, {
        url: `v1/inbox/messages/pagination/${
          auth.userUID
        }?page=${page}&length=${transactionsPerPage}&inbox_type=${'affiliate'}`,
        method: 'GET',
      })
      return Promise.resolve(response)
    },
    {
      enabled: false,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length > 0) return (pages?.length || 1) + 1
        return null
      },
      onSuccess: ({ pages }) => {
        const mappedMessages = pages.flatMap((obj) => obj)
        updateAllMessage(mappedMessages)
      },
    },
  )

  const isLoading = useMemo(() => {
    return (
      isFetchingTransaction &&
      isFetchingTransactionNextPage &&
      isFetchingAffiliate &&
      isFetchingNextAffiliatePage
    )
  }, [
    isFetchingTransaction,
    isFetchingTransactionNextPage,
    isFetchingAffiliate,
    isFetchingNextAffiliatePage,
  ])

  const { isLoading: isDoingClaimCashback, mutate: claimCashback } =
    useMutation({
      mutationFn: async (messageId: string) => {
        await fetchWithJSON<InboxMessage[]>(pigspinApiInstance, {
          url: 'v1/inbox/messages',
          method: 'PUT',
          data: {
            uid: messageId,
          },
        })
        fetchNextTransactionPage({ pageParam: 1, cancelRefetch: true })
        return Promise.resolve({})
      },
    })

  const connectInboxSocket = () => {
    connectSocket({
      token: auth.cfid?.replace('bearer', '').trim(),
      onConnected(_socket) {
        if (!isLoading) {
          fetchNextTransactionPage({ pageParam: 1, cancelRefetch: true })
          fetchNextAffiliatePage({ pageParam: 1, cancelRefetch: true })
        }
        _socket.on('toast', (data: string[]) => {
          data.forEach((message) => {
            notification.info({
              message: parse(message),
              duration: 2000,
            })
          })
        })
        _socket.on('system_status_pigbet', (data: any[]) => {
          data.forEach((message: SystemStatusResponse) => {
            collectSystemStatus(message)
          })
        })
        _socket.on('push_inbox', (data: InboxMessage[]) => {
          // NOTE : read inbox
          updateAllMessage(data)
        })
        _socket.on('delete_inbox', (data: InboxMessage[]) => {
          // NOTE : read inbox
          deleteAllMessage(data)
        })
        _socket.on('update_coin_wallet', (data: BonusEvent[]) => {
          const bonusData = data[0] || {
            activeBonus: [],
            completedBonus: [],
          }
          setBonusState({
            activeBonus: camelizeKeys(
              bonusData?.activeBonus,
            ) as ICoinWalletResult[],
            completedBonus: camelizeKeys(
              bonusData?.completedBonus,
            ) as ICoinWalletResult[],
          })
        })
        _socket.on('error', () => {
          notification.error({
            message: 'ระบบ notificatoin มีปัญหา',
            duration: 2000,
          })
        })
        _socket.on('upcoming_casino_bigwin', (data) => {
          refetchChampion()
        })
        _socket.on('upcoming_bigwin', (data: BigWinChampionDetail[]) => {
          setBigWinState((currentState: BigWinState) => {
            const pageSize = currentState.list.length
            const uniqueItemList = currentState.list.filter((bigwinCard) =>
              data.every((item) => item.uid !== bigwinCard.uid),
            )
            return {
              list: [...data, ...uniqueItemList].slice(0, pageSize),
            }
          })
        })
      },
    })
  }

  const disconnectInboxSocket = () => {
    if (socket && socket.connected) {
      disconnectSocket()
      setInboxTransaction(inboxStateCommon.defaultInboxState)
      setInboxAffiliate(inboxStateCommon.defaultInboxState)
      setInboxSystemStatus(inboxStateCommon.defaultInboxState)
    }
  }

  const readInbox = (inboxs: InboxItem[]) => {
    const read = (inbox: InboxItem): InboxMessage => ({
      ...mapToInboxMessage(inbox),
      is_read: true,
      product_type: 'PIGBET',
    })
    if (socket) {
      socket.emit('read_inbox', {
        auth: auth.cfid?.replace('bearer', '').trim(),
        data: inboxs.map(read),
      })
    }
  }

  const loadMore = (type: string) => {
    if (!isLoading) {
      switch (type) {
        case 'transaction':
          fetchNextTransactionPage()
          break
        case 'affiliate':
          fetchNextAffiliatePage()
          break
        default:
          break
      }
    }
  }

  return {
    isDoingClaimCashback,
    isLoading,
    hasNextTransactiontPage,
    hasNextAffiliatePage,
    connectInboxSocket,
    claimCashback,
    disconnectInboxSocket,
    readInbox,
    loadMore,
  }
}
