import {
  ERROR_STATUS_VALUE,
  SystemStatusType,
} from '@pig-common/recoils/modules/system-status'
import { toCamelCase } from '@pig-common/utils/utils'

const mapToastErrorState = ({
  systemStatus,
  isAuthen,
}: {
  systemStatus: SystemStatusType
  isAuthen: boolean
}) => {
  const {
    SCBError,
    isSCBUser,
    underConstruction,
    friendInvite,
    gameError,
    withdrawError,
    depositError,
    register,
  } = toCamelCase(systemStatus)

  if (underConstruction) return ERROR_STATUS_VALUE.UNDER_CONSTRUCTION
  if (register === true && !isAuthen) {
    return ERROR_STATUS_VALUE.CLOSE_REGISTER
  }

  const gameErrorValue = gameError
    ? ERROR_STATUS_VALUE.GAME
    : ERROR_STATUS_VALUE.NONE
  let SCBErrorValue =
    isSCBUser && SCBError
      ? ERROR_STATUS_VALUE.SCB_DEPOSIT
      : ERROR_STATUS_VALUE.NONE
  const withdrawErrorValue = withdrawError
    ? ERROR_STATUS_VALUE.WITHDRAW
    : ERROR_STATUS_VALUE.NONE
  let depositErrorValue = depositError
    ? ERROR_STATUS_VALUE.DEPOSIT
    : ERROR_STATUS_VALUE.NONE

  // NOTE : SCB user Special case
  if (isSCBUser && SCBError && depositError) {
    depositErrorValue = ERROR_STATUS_VALUE.DEPOSIT
    SCBErrorValue = ERROR_STATUS_VALUE.NONE
  }

  const totalValue =
    gameErrorValue * SCBErrorValue * withdrawErrorValue * depositErrorValue

  if (!(totalValue > 1) && friendInvite === true) {
    return ERROR_STATUS_VALUE.FRIEND_INVITE
  }
  return totalValue as ERROR_STATUS_VALUE
}

export default mapToastErrorState
