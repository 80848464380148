import Image, { StaticImageData } from 'next/image'
import styled from 'styled-components'

export interface LogoDecoreationProps {
  imgSrc: StaticImageData
  link?: string
  alt?: string
  height?: number
  decor?: {
    enable: boolean
    url: StaticImageData
    position?: {
      x: string
      y: string
    }
    size?: {
      x: number
      y: number
    }
  }
}

const LogoContainer = styled.div`
  height: min-content;
  font-size: 0px !important;
`
const LogoCanvas = styled.div`
  position: relative;
`
const LogoImage = styled(Image)`
  object-fit: contain;
`
const LogoDecor = styled(Image)`
  position: absolute;
`

const Logo = ({
  logo,
  onClick,
}: {
  logo: LogoDecoreationProps
  onClick: () => void
}) => {
  return (
    <LogoContainer>
      <LogoCanvas>
        <LogoImage
          src={logo?.imgSrc}
          width={160}
          height={64}
          alt={'logo'}
          onClick={onClick}
          data-testid="uikit-logo"
        />
        {logo.decor && logo.decor?.enable && (
          <LogoDecor
            src={logo.decor?.url}
            alt="logo-decoration"
            style={{
              width: logo.decor?.size?.x || 42,
              height: logo.decor?.size?.y || 42,
              left: `${logo.decor?.position?.x || '0px'}`,
              top: `${logo.decor?.position?.y || '0px'}`,
            }}
          />
        )}
      </LogoCanvas>
    </LogoContainer>
  )
}

export default Logo
