import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { COOKIES_KEY } from '@pig-common/utils/cookies'
import { PIGSPIN_URL } from '@pig-casino/constants/env'
import useGenerateSecretKey from '@pig-casino/hooks/useGenerateSecretKey'

const MAX_RETRIES = 2
export default function useAppSwitcher(props?: {
  onError: (error: unknown) => void
}) {
  const [cookies] = useCookies([COOKIES_KEY.CUSTOMER_CODE])
  const {
    data: generatedSecretKey,
    isSuccess: isGeneratedSecretSuccess,
    refetch: getGeneratedSecretKey,
  } = useGenerateSecretKey({ enabled: false })
  const handleSwitch = () => {
    getGeneratedSecretKey()
  }

  useEffect(() => {
    let retry = 0
    const attemptRedirect = async () => {
      if (isGeneratedSecretSuccess === true) {
        try {
          if (!generatedSecretKey) throw new Error('invalid key')
          if (!PIGSPIN_URL) throw new Error('invalid pigspin url')
          if (!cookies?.customer_code) throw new Error('no customer_code')
          const url = `${PIGSPIN_URL}/authenticate?keygen=${generatedSecretKey?.keygen}&customerCode=${cookies?.customer_code}`
          window.location.replace(url)
          retry = 0
        } catch (error) {
          if (retry < MAX_RETRIES) {
            retry += 1
            setTimeout(attemptRedirect, 500)
          } else {
            !!props?.onError &&
              props.onError('พบปัญหาการเปลี่ยนเว็บ กรุณาลองใหม่อีกครั้ง')
          }
        }
      }
    }
    attemptRedirect()
  }, [isGeneratedSecretSuccess, generatedSecretKey])

  return [{}, { handleSwitch }]
}
