import { api } from '@pig-common/api/api-creators'
import { CommonResponse } from '@pig-common/types/Api.type'
import {
  IPromotionsData,
  IPromotionsItem,
} from '@pig-casino/types/Promotions.type'
import { ProductType } from '@pig-common/types/Product.type'
import { AxiosRequestConfig } from 'axios'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { pigspinApiInstance } from '@pig-common/utils/api/AxiosInstance'

export interface IListParamsProps {
  productType?: ProductType
}

async function list(
  params: IListParamsProps,
): Promise<CommonResponse<IPromotionsData>> {
  return await api.get('/v1/promotions/', {
    params: {
      ...params,
      product_type: params?.productType,
    },
  })
}

async function getByCode(
  promotionCode: string | string[],
): Promise<CommonResponse<IPromotionsItem>> {
  const requestConfig: AxiosRequestConfig = {
    url: `/v1/promotions/code/${promotionCode}`,
    method: 'GET',
  }
  return await fetchWithJSON<any>(pigspinApiInstance, requestConfig)
}

export const key = {
  list: 'LIST_PROMOTIONS',
  getByCode: 'GET_PROMORIONS_BY_CODE',
}

export { list, getByCode }
