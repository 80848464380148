import axios from 'axios'
import { API_BASE, NODE_ENV, WEB_URL } from '@pig-common/constants/env'

export const api = axios.create({
  baseURL: API_BASE,
  headers: {
    'Accept-Encoding': '',
    origin: WEB_URL,
    Referer: WEB_URL,
  },
})

export const apiMock = axios.create({
  baseURL: NODE_ENV === 'development' ? 'http://localhost:3001' : API_BASE,
})
