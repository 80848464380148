import { ReactNode, useState, isValidElement, useEffect, useMemo } from 'react'
import cx from 'classnames'
import { Layout } from 'antd'
import Link from 'next/link'
import { Button, Hamburger, IHamburgerProps } from '@pig-frontend/uikit'
import { NextRouter, useRouter } from 'next/router'
import { Drawer } from '@pig-frontend/uikit'
import { LeftOutlined } from '@ant-design/icons'
import styles from './index.module.scss'
import { useNotification } from '@pig-common/hooks/useNotification'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import Logo, { LogoDecoreationProps } from '@pig-common/components/Logo'

const { Header: AntdHeader } = Layout

interface IRenderArgumentsProps {
  router: NextRouter
  isMenuLoading?: boolean
}

interface IMenusProps {
  /** If key is "chat", chat will always on header */
  key: string
  title?: string
  link?: string
  className?: string
  showOnDesktop?: boolean
  /** Hide menus from "showOnDesktop" when non paged */
  hideMenuDesktopOnNonPaged?: boolean
  showOnMobile?: boolean
  /** Always show when non page */
  showOnNonPaged?: boolean
  addMargin?: boolean
  render?: ReactNode | ((params: IRenderArgumentsProps) => ReactNode)
}

export interface IChatProps {
  icon: ReactNode
  onClick?: () => void
  isVisible?: boolean
}

export interface IHeaderProps {
  login?: {
    link?: string
    label?: string
  }
  logo?: LogoDecoreationProps
  isAuthenticated?: boolean
  isMenuLoading?: boolean
  menus?: IMenusProps[]
  chat?: IChatProps
  backBtnAction?: () => void
  hideBackBtn?: boolean
  hamburger?: IHamburgerProps
  homePages?: string[]
  children?: ReactNode
  className?: string
}

export default function Header({
  login,
  logo,
  isAuthenticated,
  isMenuLoading = false,
  hamburger,
  chat,
  menus = [],
  homePages: additionalHomePages = [],
  backBtnAction,
  hideBackBtn,
  children,
  className,
}: IHeaderProps) {
  const auth = useRecoilValue(authStateCommon.authState)
  const router = useRouter()
  const [isDrawerVisible, setDrawerVisible] = useState<boolean>(false)
  const { notification } = useNotification({
    userUID: auth.userUID,
  })
  const homePages = [
    '',
    '/',
    '/home',
    '/lobby',
    '/lobby/mobile',
    ...additionalHomePages,
  ]
  const isPagedNavbar = !homePages.includes(router.pathname)
  const isAuthenticationPage = ['/login', '/register', '/reset-pin'].includes(
    router.pathname,
  )
  const [isDomMounted, setDomMounted] = useState<boolean>(false)
  const HamBurgerIcon = useMemo(() => {
    return (
      <div
        className={cx(styles['hamburger-icon'], {
          [styles.active]: isDrawerVisible,
        })}
      >
        <div
          className={cx(styles['line-1'], {
            [styles.active]: isDrawerVisible,
          })}
        />
        <div
          className={cx(styles['line-2'], {
            [styles.active]: isDrawerVisible,
          })}
        />
        <div
          className={cx(styles['line-3'], {
            [styles.active]: isDrawerVisible,
          })}
        />
      </div>
    )
  }, [isDrawerVisible])

  useEffect(() => {
    // Disable scroll when drawer is opened
    const body = document.getElementsByTagName('body')?.[0]
    if (isDrawerVisible) {
      body.style.overflowY = 'hidden'
    } else {
      body.style.overflowY = ''
    }
  }, [isDrawerVisible])

  useEffect(() => {
    setDomMounted(true)
  }, [])

  const handleChatClick = () => {
    // Prevent overlap between chat and hamburger panel when click chat button
    setDrawerVisible(false)
    if (chat?.onClick) {
      chat?.onClick()
    }
  }

  const handleHamburgerBtnClick = () => {
    setDrawerVisible(!isDrawerVisible)
  }

  const handleClickBackButton = () => {
    if (!hideBackBtn) {
      if (isDrawerVisible) {
        setDrawerVisible(false)
      } else if (backBtnAction) {
        backBtnAction()
      } else {
        router.back()
      }
    }
  }

  return (
    <AntdHeader
      className={cx(styles.container, className, {
        [styles['paged-container']]: isAuthenticated && isPagedNavbar,
        [styles['single-logo']]: isAuthenticationPage,
      })}
    >
      {(isAuthenticated || (!isAuthenticated && isAuthenticationPage)) &&
        isPagedNavbar && (
          <LeftOutlined
            onClick={handleClickBackButton}
            className={cx(styles['back-icon'], {
              [styles.hide]: hideBackBtn,
            })}
          />
        )}

      {isDomMounted && logo?.imgSrc && (
        <Logo
          logo={logo}
          onClick={() => {
            router.push(logo?.link || isAuthenticated ? '/lobby' : '/')
            isDrawerVisible && setDrawerVisible(false)
          }}
        />
      )}
      {children ||
        (!isAuthenticationPage && isAuthenticated !== null && (
          <div className={styles.menus}>
            {isDomMounted && (
              <>
                {isAuthenticated ? (
                  <>
                    {menus.map((menu) => (
                      <div
                        key={menu.key}
                        className={cx({
                          [styles['menu-mobile']]: menu.showOnMobile,
                          [styles['menu-desktop']]: menu.showOnDesktop,
                          [styles['hide-menu-desktop-on-non-paged']]:
                            menu.hideMenuDesktopOnNonPaged && isPagedNavbar,
                          [styles['menu-with-margin']]: menu.addMargin,
                          [styles['menu-non-paged']]:
                            menu.showOnNonPaged && isPagedNavbar,
                        })}
                        data-testid="uikit-header-menu"
                      >
                        {menu?.title ? (
                          <Link
                            href={menu?.link || '#'}
                            className={styles.link}
                            data-testid="uikit-header-link"
                          >
                            <span>{menu?.title}</span>
                          </Link>
                        ) : (
                          <>
                            {isValidElement(menu?.render)
                              ? menu?.render
                              : typeof menu?.render === 'function' &&
                                menu?.render({ router, isMenuLoading })}
                          </>
                        )}
                      </div>
                    ))}
                    {hamburger && (
                      <>
                        <div
                          role="button"
                          aria-hidden
                          className={styles['chat-button']}
                          onClick={handleChatClick}
                          onKeyDown={handleChatClick}
                        >
                          {chat?.icon}
                        </div>
                        <div
                          className={styles['hamburger-button']}
                          onClick={handleHamburgerBtnClick}
                          onKeyDown={handleHamburgerBtnClick}
                        >
                          {HamBurgerIcon}
                          {notification.dailyQuest && (
                            <div className={styles['notification-badge']} />
                          )}
                        </div>
                        <Drawer
                          key="right"
                          open={isDrawerVisible}
                          placement="right"
                          closable={false}
                          forceRender
                          onClose={() => setDrawerVisible(false)}
                          getContainer={() =>
                            document.getElementsByTagName('main')[0] ||
                            document.getElementById('body')
                          }
                          className={styles.drawer}
                          rootClassName={styles['root-drawer']}
                        >
                          <Hamburger
                            onCloseDrawer={() => setDrawerVisible(false)}
                            {...hamburger}
                          />
                        </Drawer>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      role="button"
                      aria-hidden
                      className={styles['chat-button']}
                      style={{ marginRight: 10 }}
                      onClick={handleChatClick}
                      onKeyDown={handleChatClick}
                    >
                      {chat?.icon}
                    </div>
                    <Button
                      type="primary"
                      onClick={() => router.push(login?.link || '/login')}
                      data-testid="uikit-login-button"
                      className={styles.loginButton}
                    >
                      {login?.label || 'สมัคร / เข้าสู่ระบบ'}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        ))}
    </AntdHeader>
  )
}
